"use strict";
/* global dayjs, $, hyperform */

var OFFSET_HEIGHT_PX = 60;

var $help_email;
var $firstname, $lastname;

// Automatically refresh the page after x seconds of idleness
var IDLE_SECONDS = 5 * 60 // 5 minutes
//Global countdown variable
var refresh_countdown = IDLE_SECONDS

//Global map of uid to data
// DOES NOT CONTAIN PARTIES (No check-in required for parties)
var uid_to_event = {};
var vm = new Vue({
  el: '#calendar',
  delimiters: ['${', '}'],
  data: {
    events: [],
    loading: true, // Is the events list loading? Start as true for initial render
    current_studio_idx: -1, //Default invalid value
    studios: [
      { id: 1, name: "Surry Hills", code: "SURRYHILLS", default: true},
      { id: 2, name: "Wombarra", code: "WOMBARRA" },
      { id: 3, name: "Thirroul", code: "THIRROUL" },
    ],
    today: dayjs(),
    view_date: dayjs(),
    errors: []
  },
  mounted: function() {
    scrollToCurrentTime();
  },
  methods: {
    fetchEvents: function(e) {
      // e.srcElement.disabled = true
      fetchEvents(this.view_date)
    }
  },
  watch: {
    current_studio_idx: function(new_idx) {
      this.loading = true
      this.events = []
      setURL(new_idx)
      this.fetchEvents()
    },
    events: function(newEvents) {
      // Update uid to event map when Events are updated (post-check-in)
      console.log(`Updating event map with ${newEvents.length} events`)
      uid_to_event = newEvents.reduce((obj, ev) => {
        obj[ev.uid] = ev
        return obj
      }, {})
    },
    view_date: function(newDate) {
      this.loading = true
      this.events = []
      debounced_fetchEvents(newDate)
    }
  }
});

function setURL(studio_idx) {
  let s = vm.studios[studio_idx]
  history.pushState({}, s.name, `/${s.code.toLowerCase()}`)
}

function fetchEvents(day) {
  var end_day = dayjs(day).add(1, 'days')
  vm.loading = true
  $.ajax({
    url: "/calendar/events",
    dataType: "json",
    data: {
      start: day.format("YYYY-MM-DD"),
      end: end_day.format("YYYY-MM-DD"),
      studio_id: vm.studios[vm.current_studio_idx].id,
      _: Date.now() // Stop the browser caching the response
    },
    error: (jqXHR, textStatus, error) => {
      tempErrorMessage(`Failed to load events, try refreshing the page. (Error: ${textStatus} - ${error}`, 10000)
      console.log(error)
    },
    success: drawEvents,
    complete: () => vm.loading = false
  });
}

// Prevent duplicate fetchEvents calls when navigating through
// available dates
const debounced_fetchEvents = debounce(fetchEvents)

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

function getPageDefaultStudio() {
  return $("#default-studio-code").val()
}

function setStudio() {
  var studio_code = getPageDefaultStudio()

  var idx = vm.studios.findIndex(s => s.code === studio_code)
  if (idx === -1) {
    vm.current_studio_idx = 0
    var err = `Could not find studo '${studio_code}. Defaulting to Surry Hills`
    tempErrorMessage(err, 10000)
    return
  }
  vm.current_studio_idx = idx
}

function tempErrorMessage(msg, timeout) {
  vm.errors.push(msg)
  setTimeout(() => {
    let err_idx = vm.errors.findIndex(e => e === msg)
    if (err_idx !== -1) {
      Vue.delete(vm.errors, err_idx)
    }
  }, timeout)
}

$(document).ready(function() {
  hyperform(document.getElementById("checkin_form"));
  hyperform.addValidator(document.getElementById('checkin_email'), emailConfirmValidator)

  // Start refresh timer
  setInterval(() => {
    if (--refresh_countdown <= 0) {
      refresh_countdown = IDLE_SECONDS
      vm.fetchEvents()
    }
  }, 2000)  //every second

  // Reset timer on page activity
  $('body').on('mousemove touchstart keyup', () => {
    refresh_countdown = IDLE_SECONDS
  })

  $help_email = $("#help_email");
  $firstname = $("#checkin_firstname");
  $lastname = $("#checkin_lastname");

  bindCheckinHandlers()

  setStudio() // And load today's bookings

  $("#checkin_form").on("submit", function(e) {
    e.preventDefault();
    if (!this.checkValidity()) {
      return;
    }
    var $submit_btn = $("#checkin_submit");
    $submit_btn.attr("disabled", "disabled").text("Checking in...");
    var data = getCheckinFormData($(this).serializeArray());

    // On checkin close the modal, optionally opening Voucher Info modal
    var on_success = function() {
      var booking = uid_to_event[data.uid];
      var $checkin_modal = $("#checkin_modal");

      // Show voucher info modal if booked with a voucher and not a cash payment
      if (booking.voucher && data.cash_payment !== "on") {
        $checkin_modal.one("hidden.bs.modal", function() {
          showVoucherInfoModal();
        });
      }
      $checkin_modal.modal("hide");
    };

    var on_error = function(err_msg) {
      $submit_btn.removeAttr("disabled").text("Check-in");
      if (err_msg) {
        showCheckinError("Failed to check-in. Error: " + err_msg, 6000);
      }
    };

    doCheckin(data, on_success, on_error);
  });

  $("#checkin_email").on("blur", function() {
    $(this).val($(this).val().toLowerCase());
    if (this.validity.valid) {
      var email = $(this).val();
      validateEmailAddress(email);
    }
  }).on("keyup", function() {
    // If the email address becomes invalid, re-enable name inputs
    if ($firstname.attr("disabled") && !this.validity.valid) {
      enableNameInputs();
      $help_email.hide(400);
    }
  });

  // var $body = $("body");
  const calendar_date_header = document.getElementById('calendar_date_header')
  const is_staff = !!document.getElementById('is_staff').value
  const scroll_cut_off = is_staff ? 120 : 73

  $(window).scroll(function(e) {
    if (this.scrollY > scroll_cut_off) {
      calendar_date_header.classList.add('fixed')
    } else {
      calendar_date_header.classList.remove('fixed');
    }
  })


  // On check-in info capture modal, toggle cash payment information when checked
  var $cash_info_div = $("#cash-info");
  $("#chk_cash_payment").on("change", function() {
    if (this.checked) {
      $cash_info_div.show(400);
    } else {
      $cash_info_div.hide(400);
    }
  });

});

function validateEmailAddress(email_address) {
  $.ajax({
    url: "users/" + email_address
  }).done(function(response) {
    if (response.data.length === 1) {
      disableNameInputs(response.data[0].firstname, response.data[0].lastname);
      $help_email.text("Welcome back!").show(400);
      return;
    }
    // Do nothing if 0 (or more than 1, but that should never happen)
  });

}

function disableNameInputs(firstname, lastname) {
  if (firstname) {
    $firstname.val(firstname);
  }
  if (lastname) {
    $lastname.val(lastname);
  }
  $firstname.attr("disabled", true);
  $lastname.attr("disabled", true);
  var msg = "This field is disabled because a user with this email address already exists";
  $firstname.parent().attr("data-toggle", "tooltip").attr("title", msg);
  $('[data-toggle="tooltip"]').tooltip();
}

function enableNameInputs(clear) {
  if (clear) {
    $firstname.val("");
    $lastname.val("");
  }
  $firstname.removeAttr("disabled");
  $lastname.removeAttr("disabled");
  $firstname.parent().removeAttr("data-toggle").removeAttr("title").tooltip("destroy");
}

function getCheckinFormData(form_data) {
  return form_data.reduce(function (acc, cur) {
    acc[cur.name] = cur.value;
    return acc;
  }, {});
}

function clean_booking(booking) {
  booking.datetime = dayjs(booking.when)
  // hide minutes if on the hour (9 am or 9:30 am)
  let fmt = booking.datetime.minute() === 0 ? 'h a' : 'h:mm a'
  booking.display_time = booking.datetime.format(fmt)
  if (booking.is_party) {
    return
  }

  const name_bits = student_name(booking)
  booking.booking_name = name_bits.name
  booking.guest_of = name_bits.guest_of
  console.log(`${booking.uid}: ${booking.booking_name}`)
  if (!booking.booking_name && booking.idx != 0) {
    booking.booking_name = `GUEST ${booking.idx}`
  }
}

function drawEvents(eventData) {
  eventData.forEach(clean_booking)
  vm.events = eventData
}

function showCheckinModal(uid, show_cash_checkbox) {
  var $email = $("#checkin_email");
  document.getElementById('checkin_email_confirm').value = ''
  var $submit = $("#checkin_submit");
  var $uid = $("#checkin_uid");

  // Clear any stale errors
  $("#checkin_errors").empty();

  // Fire off ajax call to load form data asap.
  $.ajax({
    url: "/calendar/event",
    dataType: "json",
    data: {
      uid: uid,
      _: Date.now() // Stop the browser caching the response
    },
    success: function(event) {
      //TODO: handle event not found
      var email = event.email;
      var firstname = event.firstname;
      var lastname = event.lastname;
      if (event.idx > 0) {
        firstname = event.guest_firstname || "";
        lastname = event.guest_lastname || "";
      }
      $email.removeAttr("disabled").val(email);
      $firstname.removeAttr("disabled").val(firstname);
      $lastname.removeAttr("disabled").val(lastname);
      $submit.removeAttr("disabled");
      $("#checkin_modal").modal();
    }
  });

  // Show the modal...

  $help_email.hide();

  // ... and while it animates clear and disable form inputs
  $email.val("").attr("disabled", "disabled");
  $firstname.val("").attr("disabled", "disabled");
  $lastname.val("").attr("disabled", "disabled");
  $submit.attr("disabled", "disabled").text("Check-In");
  $uid.val(uid);

  var $checkin_cash_payment_div = $("#checkin_cash_payment");
  // Ensure it's unchecked
  $checkin_cash_payment_div.find(":checkbox").prop("checked", false);
  if (show_cash_checkbox) {
    $checkin_cash_payment_div.show(0);
  } else {
    $checkin_cash_payment_div.hide(0);
  }
}

function doCheckin(data, success_cb, error_cb) {
  data["_"] = Date.now(); // Prevent the browser from caching the response
  success_cb = (typeof success_cb === "function") ? success_cb : function (){};
  error_cb = (typeof error_cb === "function") ? error_cb : function (){};
  $.ajax({
    url: "/calendar/checkin",
    method: "POST",
    contentType: "application/json",
    dataType: "json",
    data: JSON.stringify(data),
    success: function(event) {
      let idx = vm.events.findIndex(ev => ev.uid === event.uid)
      if (idx === -1) {
        console.log("Failed to find event with uid " + event.uid)
        return
      }
      clean_booking(event)
      Vue.set(vm.events, idx, event)
      success_cb()
    },
    error: function(jqXHR) {
      //TODO: add retries
      error_cb(jqXHR.responseText);
    }
  });
}

function bindCheckinHandlers() {
  $("#calendar").on("click touch", "button.check-in", function() {
    var uid = $(this).data("uid");
    var is_voucher = uid_to_event[uid].voucher;
    showCheckinModal(uid, is_voucher);
  });

  // We can instantly check in students that have previously
  // registered their email address
  $("#calendar").on("click touch", "button.instant-check-in", function() {
    $(this).attr("disabled", "disabled").text("Checking in...");
    var $this_btn = $(this);

    var error_cb = function(err_msg) {
      $this_btn.removeAttr("disabled").html("<span class=\"glyphicon glyphicon-flash\"></span> Check-In");
      if (err_msg) {
        err_msg = err_msg.replace(/please refresh/, "<a href='/'>please refresh</a>");
        var $err = mk_error(err_msg);
        $err.insertAfter($this_btn);
        setTimeout(function() {
          $err.hide(600, function(){
            $(this).remove();
          });
        }, 4000);
      }
    };

    var uid = $(this).data("uid");
    var booking = uid_to_event[uid];

    // Prepaid, instantly check-in
    if (!booking.voucher) {
      doCheckin({uid: uid}, null, error_cb);
      return;
    }

    // Present simple modal asking if they're paying by cash

    // If the modal is dismissed re-enable the check-in button
    var dismiss_cb = error_cb; //Alias to avoid confusion

    var response_cb = function(is_cash_payment) {
      var success_cb = null;
      if (!is_cash_payment) {
        success_cb = showVoucherInfoModal;
      }
      doCheckin({uid: uid, cash_payment: !!is_cash_payment}, success_cb, error_cb);
    };

    instant_cash_modal(booking.name, response_cb, dismiss_cb);
  });
}

function showVoucherInfoModal() {
  $("#voucher_modal").modal();
}

function mk_error(err_msg) {
  return $("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button>" + err_msg + "</div>");
}

/**
 * mk_instant_cash_modal - Return a jQuery object of a modal asking the
 *                         user whether they intend to pay with cash.
 *
 * @param  {String} name The user's name to inject into the modal body
 * @returns {jQuery} Ready to add to DOM.
 */
function mk_instant_cash_modal(name) {
  return $("<div class=\"modal fade\" id=\"instant_cash_modal\" tabindex=\"-1\" role=\"dialog\"><div class=\"modal-dialog\" role=\"document\"><div class=\"modal-content\"><div class=\"modal-header\"><button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;</span></button><h4 class=\"modal-title\">Planning to pay by cash?</h4></div><div class=\"modal-body\"><p>Welcome back " + name + ", are you planning to pay by cash or card today?</p><label class=\"radio-inline\"><input type=\"radio\" name=\"cash\" value=\"yes\"> Yes (cash or card)</label><label class=\"radio-inline\"><input type=\"radio\" name=\"cash\" value=\"no\"> No (voucher)</label><div id=\"instant-cash-info\" class=\"alert alert-warning\" role=\"alert\" style=\"display: none;\">Jot your name, date and the amount paid on a piece of paper, drop it into the cookie jar along with your cash.  Speak to a staff member for card payment.</div></div><div class=\"modal-footer\"><button type=\"button\" class=\"btn btn-primary\" id=\"btn_instant_cash_checkin\">Check-in</a></div></div></div></div>");
}

/**
 * instant_cash_modal - Show a modal asking whether the guest is paying by cash
 *                      or voucher.
 *
 * @param  {String} name          The users name to insert into the message body.
 * @param  {function} response_cb A callback called with the response, "yes" if using cash, "no" if voucher.
 * @param  {function} dismiss_cb  A callback called if the modal is dismissed without providing a response.
 */
function instant_cash_modal(name, response_cb, dismiss_cb) {
  response_cb = (typeof response_cb === "function") ? response_cb : function (){};
  dismiss_cb = (typeof dismiss_cb === "function") ? dismiss_cb : function (){};

  var $modal = $("#instant_cash_modal");
  if ($modal.length == 0) {
    $modal = mk_instant_cash_modal(name);
    $modal.appendTo("body");
  }

  var $checkin_btn = $("#btn_instant_cash_checkin");
  $checkin_btn.prop("disabled", true);

  //Untick all radio buttons, add radio button change handler to enable submit btn
  $("input[name=cash]").prop("checked", false).on("change", function() {
    if ($(this).val() === "yes") {
      $("#instant-cash-info").show(400);
    } else {
      $("#instant-cash-info").hide(400);
    }
    $checkin_btn.prop("disabled", false).removeProp("disabled");
  });

  // wrap to insulate dismiss_cb from event arguments
  var dismiss_wrapper = function () {
    dismiss_cb();
  };

  $modal.modal();
  $checkin_btn.on("click touch", function() {
    $modal.off("hide.bs.modal", dismiss_wrapper); // Disable dismiss handler
    var is_cash = $("input[name=cash]:checked").val();
    response_cb(is_cash === "yes");
    $modal.modal("hide");
  });

  // Run the on_dismiss callback when the modal is first hidden
  $modal.on("hide.bs.modal", dismiss_wrapper);

  // When the modal is fully hidden remove it from the DOM
  $modal.on("hidden.bs.modal", function() {
    $("#instant_cash_modal").remove();
  });
}

function student_name(event) {
  var nameInfo = {
    name: null,
    guest_of: null,
  };
  if (event.user) { // If checked-in
    nameInfo.name = `${event.user.name}`.trim()
    nameInfo.guest_of = event.name;

  } else if (event.idx > 0) { //if a guest use name provided at booking-time
    if (event.guest_firstname || event.guest_lastname) {
      nameInfo.name = `${event.guest_firstname} ${event.guest_lastname}`.trim()
    }

    nameInfo.guest_of = event.name;
  } else { // else use booking name
    nameInfo.name = event.name;
  }
  return nameInfo;
}

// Scroll to Current sessions (approx 2 hours before present time gets us close enough)
function scrollToCurrentTime() {
  var hour = dayjs().hour() - 2;
  while (hour > 0) {
    var $time_span = $('span[data-hour=' + hour + ']');
    if ($time_span.length) {
      $("html, body").animate({ scrollTop: $time_span.offset().top - OFFSET_HEIGHT_PX });
      break;
    }
    --hour;
  }
}

function showCheckinError(msg, hide_ms) {
  var $err = $('<div class="alert alert-danger" role="alert">' + msg + "</div>");
  $err.appendTo("#checkin_errors");
  if (hide_ms) {
    setTimeout(function(){
      $err.hide(400, function() {
        $(this).remove();
      });
    }, hide_ms);
  }
}

function emailConfirmValidator(el) {
  const valid = el.value === document.getElementById('checkin_email_confirm').value
  el.setCustomValidity(valid ?  '' : 'Please confirm your email address by repeating it below')
  return valid
}
